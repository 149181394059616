define("ui/models/nodetemplate", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize", "shared/utils/util", "shared/mixins/node-driver"], function (_exports, _resource, _denormalize, _util, _nodeDriver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _resource.default.extend({
    intl: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    globalStore: Ember.inject.service(),
    type: 'nodeTemplate',
    canClone: true,
    dynamicComputedKeyName: null,
    creator: (0, _denormalize.reference)('creatorId', 'user', 'globalStore'),
    init: function init() {
      this._super.apply(this, arguments);

      var driver = this.driver;

      if (driver) {
        this.initDisplayLocation(driver);
        this.initDisplaySize(driver);
      }
    },
    config: Ember.computed('driver', function () {
      var driver = Ember.get(this, 'driver');
      return Ember.get(this, "".concat(driver, "Config"));
    }),
    displayProvider: Ember.computed('driver', 'intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var driver = Ember.get(this, 'driver');
      var key = "nodeDriver.displayName.".concat(driver);

      if (intl.exists(key)) {
        return intl.t(key);
      } else {
        return (0, _util.ucFirst)(driver);
      }
    }),
    cloneForNew: function cloneForNew() {
      var copy = this._super();

      delete copy.annotations;
      return copy;
    },
    actions: {
      edit: function edit() {
        var driver = Ember.get(this, 'driver');
        Ember.get(this, 'modalService').toggleModal('modal-edit-node-template', {
          driver: driver,
          config: Ember.get(this, "".concat(driver, "Config")),
          nodeTemplate: this,
          edit: true
        });
      },
      clone: function clone() {
        var driver = this.driver;
        Ember.get(this, 'modalService').toggleModal('modal-edit-node-template', {
          driver: driver,
          config: Ember.get(this, "".concat(driver, "Config")),
          nodeTemplate: this,
          clone: true
        });
      }
    },
    _displayVar: function _displayVar(keyOrFn) {
      var intl = Ember.get(this, 'intl');

      if (keyOrFn) {
        if (typeof keyOrFn === 'function') {
          return keyOrFn.call(this);
        } else {
          return Ember.get(this, keyOrFn) || intl.t('generic.unknown');
        }
      } else {
        return intl.t('generic.unknown');
      }
    },
    clearConfigsExcept: function clearConfigsExcept(keep) {
      var keys = this.allKeys().filter(function (x) {
        return x.endsWith('Config');
      });

      for (var key, i = 0; i < keys.length; i++) {
        key = keys[i];

        if (key !== keep && Ember.get(this, key)) {
          Ember.set(this, key, null);
        }
      }
    },
    initDisplayLocation: function initDisplayLocation(driver) {
      var location = (0, _nodeDriver.getDisplayLocation)(driver);
      var computedKeys = null;

      if (location && location.keyOrKeysToWatch) {
        computedKeys = Ember.isArray(location.keyOrKeysToWatch) ? location.keyOrKeysToWatch : [location.keyOrKeysToWatch];
        this.registerDynamicComputedProperty('displayLocation', computedKeys, location.getDisplayProperty);
      } else {
        Ember.set(this, 'displayLocation', Ember.get(this, 'config.region') || 'N/A');
      }
    },
    initDisplaySize: function initDisplaySize(driver) {
      var size = (0, _nodeDriver.getDisplaySize)(driver);
      var computedKeys = null;

      if (size && size.keyOrKeysToWatch) {
        computedKeys = Ember.isArray(size.keyOrKeysToWatch) ? size.keyOrKeysToWatch : [size.keyOrKeysToWatch];
        this.registerDynamicComputedProperty('displaySize', computedKeys, size.getDisplayProperty);
      } else {
        Ember.set(this, 'displaySize', Ember.get(this, 'config.size') || 'N/A');
      }
    },
    registerDynamicComputedProperty: function registerDynamicComputedProperty(propertyName, watchedKeys, key) {
      var _Ember;

      Ember.set(this, 'dynamicComputedKeyName', key);
      Ember.defineProperty(this, propertyName, (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(watchedKeys).concat(['dynamicComputedKeyName', this.getDisplayVar])));
    },
    getDisplayVar: function getDisplayVar() {
      return this._displayVar(Ember.get(this, 'dynamicComputedKeyName'));
    }
  });

  _exports.default = _default;
});