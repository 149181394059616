define("ui/models/cluster", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize", "shared/mixins/resource-usage", "shared/mixins/grafana", "ui/utils/constants", "moment", "jsondiffpatch", "semver"], function (_exports, _resource, _denormalize, _resourceUsage, _grafana, _constants, _moment, _jsondiffpatch, _semver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEFAULT_EKS_CONFIG = _exports.DEFAULT_NODE_GROUP_CONFIG = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var TRUE = 'True';
  var CLUSTER_TEMPLATE_ID_PREFIX = 'cattle-global-data:';
  var SCHEDULE_CLUSTER_SCAN_QUESTION_KEY = 'scheduledClusterScan.enabled';
  var DEFAULT_NODE_GROUP_CONFIG = {
    desiredSize: 2,
    diskSize: 20,
    ec2SshKey: '',
    gpu: false,
    instanceType: 't3.medium',
    maxSize: 2,
    minSize: 2,
    nodegroupName: '',
    subnets: [],
    type: 'nodeGroup'
  };
  _exports.DEFAULT_NODE_GROUP_CONFIG = DEFAULT_NODE_GROUP_CONFIG;
  var DEFAULT_EKS_CONFIG = {
    amazonCredentialSecret: '',
    displayName: '',
    imported: false,
    kmsKey: '',
    kubernetesVersion: '',
    loggingTypes: [],
    nodeGroups: [],
    privateAccess: false,
    publicAccess: true,
    publicAccessSources: [],
    region: 'us-west-2',
    secretsEncryption: false,
    securityGroups: [],
    serviceRole: '',
    subnets: [],
    tags: {},
    type: 'eksclusterconfigspec'
  };
  _exports.DEFAULT_EKS_CONFIG = DEFAULT_EKS_CONFIG;

  var _default = _resource.default.extend(_grafana.default, _resourceUsage.default, {
    globalStore: Ember.inject.service(),
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    scope: Ember.inject.service(),
    clusterRoleTemplateBindings: (0, _denormalize.hasMany)('id', 'clusterRoleTemplateBinding', 'clusterId'),
    etcdbackups: (0, _denormalize.hasMany)('id', 'etcdbackup', 'clusterId'),
    namespaces: (0, _denormalize.hasMany)('id', 'namespace', 'clusterId'),
    nodePools: (0, _denormalize.hasMany)('id', 'nodePool', 'clusterId'),
    nodes: (0, _denormalize.hasMany)('id', 'node', 'clusterId'),
    projects: (0, _denormalize.hasMany)('id', 'project', 'clusterId'),
    clusterScans: (0, _denormalize.hasMany)('id', 'clusterScan', 'clusterId'),
    expiringCerts: null,
    grafanaDashboardName: 'Cluster',
    isMonitoringReady: false,
    _cachedConfig: null,
    clusterTemplate: (0, _denormalize.reference)('clusterTemplateId'),
    clusterTemplateRevision: (0, _denormalize.reference)('clusterTemplateRevisionId'),
    machines: Ember.computed.alias('nodes'),
    roleTemplateBindings: Ember.computed.alias('clusterRoleTemplateBindings'),
    isAKS: Ember.computed.equal('driver', 'azureKubernetesService'),
    isGKE: Ember.computed.equal('driver', 'googleKubernetesEngine'),
    runningClusterScans: Ember.computed.filterBy('clusterScans', 'isRunning', true),
    conditionsDidChange: Ember.on('init', Ember.observer('enableClusterMonitoring', 'conditions.@each.status', function () {
      if (!Ember.get(this, 'enableClusterMonitoring')) {
        return false;
      }

      var conditions = Ember.get(this, 'conditions') || [];
      var ready = conditions.findBy('type', 'MonitoringEnabled');
      var status = ready && Ember.get(ready, 'status') === 'True';

      if (status !== Ember.get(this, 'isMonitoringReady')) {
        Ember.set(this, 'isMonitoringReady', status);
      }
    })),
    clusterTemplateDisplayName: Ember.computed('clusterTemplate.{displayName,name}', 'clusterTemplateId', function () {
      var displayName = Ember.get(this, 'clusterTemplate.displayName');
      var clusterTemplateId = (Ember.get(this, 'clusterTemplateId') || '').replace(CLUSTER_TEMPLATE_ID_PREFIX, '');
      return displayName || clusterTemplateId;
    }),
    clusterTemplateRevisionDisplayName: Ember.computed('clusterTemplateRevision.{displayName,name}', 'clusterTemplateRevisionId', function () {
      var displayName = Ember.get(this, 'clusterTemplateRevision.displayName');
      var revisionId = (Ember.get(this, 'clusterTemplateRevisionId') || '').replace(CLUSTER_TEMPLATE_ID_PREFIX, '');
      return displayName || revisionId;
    }),
    isClusterTemplateUpgradeAvailable: Ember.computed('clusterTemplate.latestRevision', 'clusterTemplate.latestRevision.id', 'clusterTemplateRevision.id', function () {
      var latestClusterTemplateRevisionId = Ember.get(this, 'clusterTemplate.latestRevision.id');
      var currentClusterTemplateRevisionId = Ember.get(this, 'clusterTemplateRevision.id');
      return latestClusterTemplateRevisionId && currentClusterTemplateRevisionId && currentClusterTemplateRevisionId !== latestClusterTemplateRevisionId;
    }),
    getAltActionDelete: Ember.computed('action.remove', function () {
      // eslint-disable-line
      return Ember.get(this, 'canBulkRemove') ? 'delete' : null;
    }),
    hasSessionToken: Ember.computed('annotations', function () {
      var sessionTokenLabel = "".concat((Ember.get(this, 'annotations') || {})[_constants.default.LABEL.EKS_SESSION_TOKEN]);
      var hasSessionToken = false;

      if (sessionTokenLabel === 'undefined' || sessionTokenLabel === 'false') {
        hasSessionToken = false;
      } else {
        hasSessionToken = true;
      }

      return hasSessionToken;
    }),
    canRotateCerts: Ember.computed('actionLinks.rotateCertificates', function () {
      return !!this.actionLinks.rotateCertificates;
    }),
    canBulkRemove: Ember.computed('action.remove', function () {
      // eslint-disable-line
      return Ember.get(this, 'hasSessionToken') ? false : true;
    }),
    canSaveAsTemplate: Ember.computed('actionLinks.saveAsTemplate', 'isReady', 'clusterTemplateRevisionId', 'clusterTemplateId', function () {
      var actionLinks = this.actionLinks,
          isReady = this.isReady,
          clusterTemplateRevisionId = this.clusterTemplateRevisionId,
          clusterTemplateId = this.clusterTemplateId;

      if (!isReady) {
        return false;
      }

      if (clusterTemplateRevisionId || clusterTemplateId) {
        return false;
      }

      return !!actionLinks.saveAsTemplate;
    }),
    configName: Ember.computed('driver', 'state', function () {
      var keys = this.allKeys().filter(function (x) {
        return x.endsWith('Config');
      });

      for (var key, i = 0; i < keys.length; i++) {
        key = keys[i];

        if (Ember.get(this, key)) {
          return key;
        }
      }

      return null;
    }),
    isReady: Ember.computed('conditions.@each.status', function () {
      return this.hasCondition('Ready');
    }),
    isRKE: Ember.computed('configName', function () {
      return Ember.get(this, 'configName') === 'rancherKubernetesEngineConfig';
    }),
    displayLocation: Ember.computed('configName', function () {
      var configName = this.configName;

      if (configName) {
        return Ember.get(this, "".concat(configName, ".region")) || Ember.get(this, "".concat(configName, ".regionId")) || Ember.get(this, "".concat(configName, ".location")) || Ember.get(this, "".concat(configName, ".zone")) || Ember.get(this, "".concat(configName, ".zoneId"));
      }

      return '';
    }),
    clusterProvider: Ember.computed('configName', 'nodePools.@each.{driver,nodeTemplateId}', 'driver', function () {
      var pools = Ember.get(this, 'nodePools') || [];
      var firstPool = pools.objectAt(0);

      switch (Ember.get(this, 'configName')) {
        case 'amazonElasticContainerServiceConfig':
          return 'amazoneks';

        case 'eksConfig':
          return 'amazoneksv2';

        case 'azureKubernetesServiceConfig':
          return 'azureaks';

        case 'googleKubernetesEngineConfig':
          return 'googlegke';

        case 'tencentEngineConfig':
          return 'tencenttke';

        case 'huaweiEngineConfig':
          return 'huaweicce';

        case 'okeEngineConfig':
          return 'oracleoke';

        case 'rke2Config':
          return 'rke2';

        case 'rancherKubernetesEngineConfig':
          if (!pools.length) {
            return 'custom';
          }

          return firstPool.driver || Ember.get(firstPool, 'nodeTemplate.driver') || null;

        default:
          if (Ember.get(this, 'driver') && Ember.get(this, 'configName')) {
            return Ember.get(this, 'driver');
          } else {
            return 'import';
          }

      }
    }),
    displayProvider: Ember.computed('configName', 'nodePools.@each.displayProvider', 'intl.locale', 'driver', function () {
      var intl = Ember.get(this, 'intl');
      var pools = Ember.get(this, 'nodePools');
      var firstPool = (pools || []).objectAt(0);
      var configName = Ember.get(this, 'configName');

      switch (configName) {
        case 'amazonElasticContainerServiceConfig':
        case 'eksConfig':
          return intl.t('clusterNew.amazoneks.shortLabel');

        case 'azureKubernetesServiceConfig':
          return intl.t('clusterNew.azureaks.shortLabel');

        case 'googleKubernetesEngineConfig':
          return intl.t('clusterNew.googlegke.shortLabel');

        case 'tencentEngineConfig':
          return intl.t('clusterNew.tencenttke.shortLabel');

        case 'aliyunEngineConfig':
          return intl.t('clusterNew.aliyunack.shortLabel');

        case 'huaweiEngineConfig':
          return intl.t('clusterNew.huaweicce.shortLabel');

        case 'okeEngineConfig':
          return intl.t('clusterNew.oracleoke.shortLabel');

        case 'k3sConfig':
          return intl.t('clusterNew.k3simport.shortLabel');

        case 'rke2Config':
        case 'rancherKubernetesEngineConfig':
          var shortLabel = configName === 'rancherKubernetesEngineConfig' ? 'clusterNew.rke.shortLabel' : 'clusterNew.rke2.shortLabel';

          if (!!pools) {
            if (firstPool) {
              return Ember.get(firstPool, 'displayProvider') ? Ember.get(firstPool, 'displayProvider') : intl.t(shortLabel);
            } else {
              return intl.t(shortLabel);
            }
          } else {
            return intl.t('clusterNew.custom.shortLabel');
          }

        default:
          if (Ember.get(this, 'driver') && Ember.get(this, 'configName')) {
            return Ember.get(this, 'driver').capitalize();
          } else {
            return intl.t('clusterNew.import.shortLabel');
          }

      }
    }),
    systemProject: Ember.computed('projects.@each.isSystemProject', function () {
      var projects = (Ember.get(this, 'projects') || []).filterBy('isSystemProject', true);
      return Ember.get(projects, 'firstObject');
    }),
    canSaveMonitor: Ember.computed('actionLinks.{editMonitoring,enableMonitoring}', 'enableClusterMonitoring', function () {
      var action = Ember.get(this, 'enableClusterMonitoring') ? 'editMonitoring' : 'enableMonitoring';
      return !!this.hasAction(action);
    }),
    canDisableMonitor: Ember.computed('actionLinks.disableMonitoring', function () {
      return !!this.hasAction('disableMonitoring');
    }),
    defaultProject: Ember.computed('projects.@each.{name,clusterOwner}', function () {
      var projects = Ember.get(this, 'projects') || [];
      var out = projects.findBy('isDefault');

      if (out) {
        return out;
      }

      out = projects.findBy('clusterOwner', true);

      if (out) {
        return out;
      }

      out = projects.objectAt(0);
      return out;
    }),
    nodeGroupVersionUpdate: Ember.computed('eksStatus.upstreamSpec.kubernetesVersion', 'eksStatus.upstreamSpec.nodeGroups.@each.version', function () {
      if (Ember.isEmpty(Ember.get(this, 'eksStatus.upstreamSpec.nodeGroups'))) {
        return false;
      }

      var kubernetesVersion = Ember.get(this, 'eksStatus.upstreamSpec.kubernetesVersion');
      var nodeGroupVersions = (Ember.get(this, 'eksStatus.upstreamSpec.nodeGroups') || []).getEach('version');
      return nodeGroupVersions.any(function (ngv) {
        return _semver.default.lt(_semver.default.coerce(ngv), _semver.default.coerce(kubernetesVersion));
      });
    }),
    certsExpiring: Ember.computed('certificatesExpiration', function () {
      var _this$certificatesExp = this.certificatesExpiration,
          certificatesExpiration = _this$certificatesExp === void 0 ? {} : _this$certificatesExp,
          expiringCerts = this.expiringCerts;

      if (!expiringCerts) {
        expiringCerts = [];
      }

      if (!Ember.isEmpty(certificatesExpiration)) {
        var expKeys = Object.keys(certificatesExpiration);
        expKeys.forEach(function (kee) {
          var certDate = Ember.get(certificatesExpiration[kee], 'expirationDate');
          var expirey = (0, _moment.default)(certDate);
          var diff = expirey.diff((0, _moment.default)());

          if (diff < 2592000000) {
            // milliseconds in a month
            expiringCerts.pushObject({
              expiringCertName: kee,
              milliUntil: diff,
              exactDateTime: certDate
            });
          }
        });
        Ember.set(this, 'expiringCerts', expiringCerts);
        return expiringCerts.length > 0;
      }

      return false;
    }),
    availableActions: Ember.computed('actionLinks.rotateCertificates', 'canSaveAsTemplate', 'isClusterScanDisabled', function () {
      var a = Ember.get(this, 'actionLinks') || {};
      return [{
        label: 'action.rotate',
        icon: 'icon icon-history',
        action: 'rotateCertificates',
        enabled: !!a.rotateCertificates
      }, {
        label: 'action.backupEtcd',
        icon: 'icon icon-history',
        action: 'backupEtcd',
        enabled: !!a.backupEtcd
      }, {
        label: 'action.restoreFromEtcdBackup',
        icon: 'icon icon-history',
        action: 'restoreFromEtcdBackup',
        enabled: !!a.restoreFromEtcdBackup
      }, {
        label: 'action.saveAsTemplate',
        icon: 'icon icon-file',
        action: 'saveAsTemplate',
        enabled: this.canSaveAsTemplate
      }, {
        label: 'action.runCISScan',
        icon: 'icon icon-play',
        action: 'runCISScan',
        enabled: !this.isClusterScanDisabled
      }];
    }),
    isVxlan: Ember.computed('rancherKubernetesEngineConfig.network.options.flannel_backend_type', function () {
      var backend = Ember.get(this, 'rancherKubernetesEngineConfig.network.options.flannel_backend_type');
      return backend === 'vxlan';
    }),
    isWindows: Ember.computed('windowsPreferedCluster', function () {
      return !!Ember.get(this, 'windowsPreferedCluster');
    }),
    isClusterScanDown: Ember.computed('systemProject', 'state', 'actionLinks.runSecurityScan', 'isWindows', function () {
      return !Ember.get(this, 'systemProject') || Ember.get(this, 'state') !== 'active' || !Ember.get(this, 'actionLinks.runSecurityScan') || Ember.get(this, 'isWindows');
    }),
    isAddClusterScanScheduleDisabled: Ember.computed('isClusterScanDown', 'scheduledClusterScan.enabled', 'clusterTemplateRevision', 'clusterTemplateRevision.questions.[]', function () {
      if (Ember.get(this, 'clusterTemplateRevision') === null) {
        return Ember.get(this, 'isClusterScanDown');
      }

      if (Ember.get(this, 'isClusterScanDown')) {
        return true;
      }

      if (Ember.get(this, 'scheduledClusterScan.enabled')) {
        return false;
      }

      return !Ember.get(this, 'clusterTemplateRevision.questions') || Ember.get(this, 'clusterTemplateRevision.questions').every(function (question) {
        return question.variable !== SCHEDULE_CLUSTER_SCAN_QUESTION_KEY;
      });
    }),
    isClusterScanDisabled: Ember.computed('runningClusterScans.length', 'isClusterScanDown', function () {
      return Ember.get(this, 'runningClusterScans.length') > 0 || Ember.get(this, 'isClusterScanDown');
    }),
    unhealthyComponents: Ember.computed('componentStatuses.@each.conditions', function () {
      return (Ember.get(this, 'componentStatuses') || []).filter(function (s) {
        return !(s.conditions || []).any(function (c) {
          return c.status === 'True';
        });
      });
    }),
    masterNodes: Ember.computed('nodes.@each.{state,labels}', function () {
      return (this.nodes || []).filter(function (node) {
        return node.labels && node.labels[_constants.default.NODES.MASTER_NODE];
      });
    }),
    inactiveNodes: Ember.computed('nodes.@each.state', function () {
      return (Ember.get(this, 'nodes') || []).filter(function (n) {
        return _constants.default.ACTIVEISH_STATES.indexOf(Ember.get(n, 'state')) === -1;
      });
    }),
    unhealthyNodes: Ember.computed('nodes.@each.conditions', function () {
      var out = [];
      (Ember.get(this, 'nodes') || []).forEach(function (n) {
        var conditions = Ember.get(n, 'conditions') || [];
        var outOfDisk = conditions.find(function (c) {
          return c.type === 'OutOfDisk';
        });
        var diskPressure = conditions.find(function (c) {
          return c.type === 'DiskPressure';
        });
        var memoryPressure = conditions.find(function (c) {
          return c.type === 'MemoryPressure';
        });

        if (outOfDisk && Ember.get(outOfDisk, 'status') === TRUE) {
          out.push({
            displayName: Ember.get(n, 'displayName'),
            error: 'outOfDisk'
          });
        }

        if (diskPressure && Ember.get(diskPressure, 'status') === TRUE) {
          out.push({
            displayName: Ember.get(n, 'displayName'),
            error: 'diskPressure'
          });
        }

        if (memoryPressure && Ember.get(memoryPressure, 'status') === TRUE) {
          out.push({
            displayName: Ember.get(n, 'displayName'),
            error: 'memoryPressure'
          });
        }
      });
      return out;
    }),
    displayWarnings: Ember.computed('unhealthyNodes.[]', 'clusterProvider', 'inactiveNodes.[]', 'unhealthyComponents.[]', function () {
      var intl = Ember.get(this, 'intl');
      var out = [];
      var unhealthyComponents = Ember.get(this, 'unhealthyComponents') || [];
      var inactiveNodes = Ember.get(this, 'inactiveNodes') || [];
      var unhealthyNodes = Ember.get(this, 'unhealthyNodes') || [];
      var clusterProvider = Ember.get(this, 'clusterProvider');
      var grayOut = _constants.default.GRAY_OUT_SCHEDULER_STATUS_PROVIDERS.indexOf(clusterProvider) > -1;
      unhealthyComponents.forEach(function (component) {
        if (grayOut && (Ember.get(component, 'name') === 'scheduler' || Ember.get(component, 'name') === 'controller-manager')) {
          return;
        }

        out.pushObject(intl.t('clusterDashboard.alert.component', {
          component: Ember.get(component, 'name')
        }));
      });
      inactiveNodes.forEach(function (node) {
        out.pushObject(intl.t('clusterDashboard.alert.node', {
          node: Ember.get(node, 'displayName')
        }));
      });
      unhealthyNodes.forEach(function (node) {
        out.pushObject(intl.t("clusterDashboard.alert.nodeCondition.".concat(Ember.get(node, 'error')), {
          node: Ember.get(node, 'displayName')
        }));
      });
      return out;
    }),
    actions: {
      backupEtcd: function backupEtcd() {
        var _this = this;

        var getBackupType = function getBackupType() {
          var services = Ember.get(_this, 'rancherKubernetesEngineConfig.services.etcd');

          if (Ember.get(services, 'cachedConfig')) {
            if (Ember.isEmpty(services.cachedConfig.s3BackupConfig)) {
              return 'local';
            } else if (!Ember.isEmpty(services.cachedConfig.s3BackupConfig)) {
              return 's3';
            }
          }
        };

        var backupType = getBackupType();
        var successTitle = this.intl.t('action.backupEtcdMessage.success.title');
        var successMessage = this.intl.t('action.backupEtcdMessage.success.message', {
          clusterId: this.displayName || this.id,
          backupType: backupType
        });
        this.doAction('backupEtcd').then(function () {
          return _this.growl.success(successTitle, successMessage);
        }).catch(function (err) {
          return _this.growl.fromError(err);
        });
      },
      restoreFromEtcdBackup: function restoreFromEtcdBackup(options) {
        Ember.get(this, 'modalService').toggleModal('modal-restore-backup', {
          cluster: this,
          selection: (options || {}).selection
        });
      },
      promptDelete: function promptDelete() {
        var hasSessionToken = Ember.get(this, 'canBulkRemove') ? false : true; // canBulkRemove returns true of the session token is set false

        if (hasSessionToken) {
          Ember.set(this, "".concat(Ember.get(this, 'configName'), ".accessKey"), null);
          Ember.get(this, 'modalService').toggleModal('modal-delete-eks-cluster', {
            model: this
          });
        } else {
          Ember.get(this, 'modalService').toggleModal('confirm-delete', {
            escToClose: true,
            resources: [this]
          });
        }
      },
      edit: function edit() {
        var additionalQueryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var provider = Ember.get(this, 'clusterProvider') || Ember.get(this, 'driver');
        var queryParams = {
          queryParams: _objectSpread({
            provider: provider
          }, additionalQueryParams)
        };

        if (provider === 'amazoneks' && !Ember.isEmpty(Ember.get(this, 'eksConfig'))) {
          Ember.set(queryParams, 'queryParams.provider', 'amazoneksv2');
        }

        if (this.clusterTemplateRevisionId) {
          Ember.set(queryParams, 'queryParams.clusterTemplateRevision', this.clusterTemplateRevisionId);
        }

        this.router.transitionTo('authenticated.cluster.edit', Ember.get(this, 'id'), queryParams);
      },
      scaleDownPool: function scaleDownPool(id) {
        var pool = (Ember.get(this, 'nodePools') || []).findBy('id', id);

        if (pool) {
          pool.incrementQuantity(-1);
        }
      },
      scaleUpPool: function scaleUpPool(id) {
        var pool = (Ember.get(this, 'nodePools') || []).findBy('id', id);

        if (pool) {
          pool.incrementQuantity(1);
        }
      },
      saveAsTemplate: function saveAsTemplate() {
        this.modalService.toggleModal('modal-save-rke-template', {
          cluster: this
        });
      },
      runCISScan: function runCISScan(options) {
        this.get('modalService').toggleModal('run-scan-modal', {
          closeWithOutsideClick: true,
          cluster: this,
          onRun: (options || {}).onRun
        });
      },
      rotateCertificates: function rotateCertificates() {
        var model = this;
        Ember.get(this, 'modalService').toggleModal('modal-rotate-certificates', {
          model: model,
          serviceDefaults: Ember.get(this, 'globalStore').getById('schema', 'rotatecertificateinput').optionsFor('services')
        });
      }
    },
    clearConfigFieldsForClusterTemplate: function clearConfigFieldsForClusterTemplate() {
      var _this2 = this;

      var clearedNull = ['localClusterAuthEndpoint', 'rancherKubernetesEngineConfig', 'enableNetworkPolicy'];
      var clearedDelete = ['defaultClusterRoleForProjectMembers', 'defaultPodSecurityPolicyTemplateId'];
      var localClusterAuthEndpoint = this.localClusterAuthEndpoint,
          rancherKubernetesEngineConfig = this.rancherKubernetesEngineConfig,
          enableNetworkPolicy = this.enableNetworkPolicy,
          defaultClusterRoleForProjectMembers = this.defaultClusterRoleForProjectMembers,
          defaultPodSecurityPolicyTemplateId = this.defaultPodSecurityPolicyTemplateId;
      var cachedConfig = {
        localClusterAuthEndpoint: localClusterAuthEndpoint,
        rancherKubernetesEngineConfig: rancherKubernetesEngineConfig,
        enableNetworkPolicy: enableNetworkPolicy,
        defaultClusterRoleForProjectMembers: defaultClusterRoleForProjectMembers,
        defaultPodSecurityPolicyTemplateId: defaultPodSecurityPolicyTemplateId
      }; // set this incase we fail to save the cluster;

      Ember.set(this, '_cachedConfig', cachedConfig);
      clearedDelete.forEach(function (c) {
        return delete _this2[c];
      });
      clearedNull.forEach(function (c) {
        return Ember.set(_this2, c, null);
      });
    },
    clearProvidersExcept: function clearProvidersExcept(keep) {
      var keys = this.allKeys().filter(function (x) {
        return x.endsWith('Config');
      });

      for (var key, i = 0; i < keys.length; i++) {
        key = keys[i];

        if (key !== keep && Ember.get(this, key)) {
          Ember.set(this, key, null);
        }
      }
    },
    delete: function _delete()
    /* arguments*/
    {
      var _this3 = this;

      var promise = this._super.apply(this, arguments);

      return promise.then(function ()
      /* resp */
      {
        if (Ember.get(_this3, 'scope.currentCluster.id') === Ember.get(_this3, 'id')) {
          Ember.get(_this3, 'router').transitionTo('global-admin.clusters');
        }
      });
    },
    getOrCreateToken: function getOrCreateToken() {
      var _this4 = this;

      var globalStore = Ember.get(this, 'globalStore');
      var id = Ember.get(this, 'id');
      return globalStore.findAll('clusterRegistrationToken', {
        forceReload: true
      }).then(function (tokens) {
        var token = tokens.filterBy('clusterId', id)[0];

        if (token) {
          return Ember.RSVP.resolve(token);
        } else {
          token = Ember.get(_this4, 'globalStore').createRecord({
            type: 'clusterRegistrationToken',
            clusterId: id
          });
          return token.save();
        }
      });
    },
    waitForClusterTemplateToBeAttached: function waitForClusterTemplateToBeAttached() {
      var _this5 = this;

      return this._waitForTestFn(function () {
        return _this5.hasClusterTemplate();
      }, "Wait for Cluster Template to be attached");
    },
    hasClusterTemplate: function hasClusterTemplate() {
      var clusterTemplateId = this.clusterTemplateId,
          clusterTemplateRevisionId = this.clusterTemplateRevisionId;

      if (Ember.isEmpty(clusterTemplateId) && Ember.isEmpty(clusterTemplateRevisionId)) {
        return false;
      }

      return true;
    },
    save: function save(opt) {
      var globalStore = this.globalStore,
          eksConfig = this.eksConfig;

      if (Ember.get(this, 'driver') === 'EKS' || this.isObject(Ember.get(this, 'eksConfig')) && !this.isEmptyObject(Ember.get(this, 'eksConfig'))) {
        var options = _objectSpread(_objectSpread({}, opt), {}, {
          data: {
            name: this.name,
            eksConfig: {}
          }
        });

        var eksClusterConfigSpec = globalStore.getById('schema', 'eksclusterconfigspec');
        var nodeGroupConfigSpec = globalStore.getById('schema', 'nodegroup');

        if (Ember.isEmpty(this.id)) {
          sanitizeConfigs(eksClusterConfigSpec, nodeGroupConfigSpec);

          if (this.name !== Ember.get(this, 'eksConfig.displayName')) {
            Ember.set(this, 'eksConfig.displayName', this.name);
          }

          return this._super.apply(this, arguments);
        } else {
          var config = _jsondiffpatch.default.clone(Ember.get(this, 'eksConfig'));

          var upstreamSpec = _jsondiffpatch.default.clone(Ember.get(this, 'eksStatus.upstreamSpec'));

          if (Ember.isEmpty(upstreamSpec)) {
            sanitizeConfigs(eksClusterConfigSpec, nodeGroupConfigSpec);
            return this._super.apply(this, arguments);
          }

          Ember.set(options, 'data.eksConfig', this.diffEksUpstream(upstreamSpec, config));

          if (!Ember.isEmpty(Ember.get(options, 'data.eksConfig.nodeGroups'))) {
            Ember.get(options, 'data.eksConfig.nodeGroups').forEach(function (ng) {
              replaceNullWithEmptyDefaults(ng, Ember.get(nodeGroupConfigSpec, 'resourceFields'));
            });
          }

          if (Ember.get(options, 'qp._replace')) {
            delete options.qp['_replace'];
          }

          return this._super(options);
        }
      } else {
        return this._super.apply(this, arguments);
      }

      function sanitizeConfigs(eksClusterConfigSpec, nodeGroupConfigSpec) {
        replaceNullWithEmptyDefaults(eksConfig, Ember.get(eksClusterConfigSpec, 'resourceFields'));

        if (!Ember.isEmpty(Ember.get(eksConfig, 'nodeGroups'))) {
          Ember.get(eksConfig, 'nodeGroups').forEach(function (ng) {
            replaceNullWithEmptyDefaults(ng, Ember.get(nodeGroupConfigSpec, 'resourceFields'));
          });
        }
      }

      function replaceNullWithEmptyDefaults(config, resourceFields) {
        Object.keys(config).forEach(function (ck) {
          var configValue = Ember.get(config, ck);

          if (configValue === null || typeof configValue === 'undefined') {
            var resourceField = resourceFields[ck];

            if (resourceField.type === 'string') {
              Ember.set(config, ck, '');
            } else if (resourceField.type.includes('array')) {
              Ember.set(config, ck, []);
            } else if (resourceField.type.includes('map')) {
              Ember.set(config, ck, {});
            } else if (resourceField.type.includes('boolean')) {
              if (resourceField.default) {
                Ember.set(config, ck, resourceField.default);
              } else {
                if (!Ember.isEmpty(Ember.get(DEFAULT_EKS_CONFIG, ck)) || !Ember.isEmpty(Ember.get(DEFAULT_NODE_GROUP_CONFIG, ck))) {
                  var match = Ember.isEmpty(Ember.get(DEFAULT_EKS_CONFIG, ck)) ? Ember.get(DEFAULT_NODE_GROUP_CONFIG, ck) : Ember.get(DEFAULT_EKS_CONFIG, ck);
                  Ember.set(config, ck, match);
                } // we shouldn't get here, there are not that many fields in EKS and I've set the defaults for bools that are there
                // but if we do hit this branch my some magic case imo a bool isn't something we can default cause its unknown...just dont do anything.

              }
            }
          }
        });
        return config;
      }
    },
    diffEksUpstream: function diffEksUpstream(lhs, rhs) {
      var _this6 = this;

      // this is NOT a generic object diff.
      // It tries to be as generic as possible but it does make certain assumptions regarding nulls and emtpy arrays/objects
      // if LHS (upstream) is null and RHS (eks config) is empty we do not count this as a change
      // additionally null values on the RHS will be ignored as null cant be sent in this case
      var delta = {};
      var rhsKeys = Object.keys(rhs);
      rhsKeys.forEach(function (k) {
        if (k === 'type') {
          return;
        }

        var lhsMatch = Ember.get(lhs, k);
        var rhsMatch = Ember.get(rhs, k);

        try {
          if (Ember.isEqual(JSON.stringify(lhsMatch), JSON.stringify(rhsMatch))) {
            return;
          }
        } catch (e) {}

        if (k === 'nodeGroups' || k === 'tags') {
          if (!Ember.isEmpty(rhsMatch)) {
            // node groups need ALL data so short circut and send it all
            Ember.set(delta, k, rhsMatch);
          } else {
            // all node groups were deleted
            Ember.set(delta, k, []);
          }

          return;
        }

        if (Ember.isEmpty(lhsMatch) || _this6.isEmptyObject(lhsMatch)) {
          if (Ember.isEmpty(rhsMatch) || _this6.isEmptyObject(rhsMatch)) {
            if (lhsMatch !== null && (Ember.isArray(rhsMatch) || _this6.isObject(rhsMatch))) {
              // Empty Arrays and Empty Maps must be sent as such unless the upstream value is null, then the empty array or obj is just a init value from ember
              Ember.set(delta, k, rhsMatch);
            }

            return;
          } else {
            // lhs is empty, rhs is not, just set
            Ember.set(delta, k, rhsMatch);
          }
        } else {
          if (rhsMatch !== null) {
            // entry in og obj
            if (Ember.isArray(lhsMatch)) {
              if (Ember.isArray(rhsMatch)) {
                if (!Ember.isEmpty(rhsMatch) && rhsMatch.every(function (m) {
                  return _this6.isObject(m);
                })) {
                  // You have more diffing to do
                  rhsMatch.forEach(function (match) {
                    // our most likely candiate for a match is node group name, but lets check the others just incase.
                    var matchId = Ember.get(match, 'name') || Ember.get(match, 'id') || false;

                    if (matchId) {
                      var lmatchIdx; // we have soime kind of identifier to find a match in the upstream, so we can diff and insert to new array

                      var lMatch = lhsMatch.find(function (l, idx) {
                        var lmatchId = Ember.get(l, 'name') || Ember.get(l, 'id');

                        if (lmatchId === matchId) {
                          lmatchIdx = idx;
                          return l;
                        }
                      });

                      if (lMatch) {
                        // we have a match in the upstream, meaning we've probably made updates to the object itself
                        var diffedMatch = _this6.diffEksUpstream(lMatch, match);

                        if (!Ember.isArray(Ember.get(delta, k))) {
                          Ember.set(delta, k, [diffedMatch]);
                        } else {
                          // diff and push into new array
                          delta[k].insertAt(lmatchIdx, diffedMatch);
                        }
                      } else {
                        // no match in upstream, new entry
                        if (!Ember.isArray(Ember.get(delta, k))) {
                          Ember.set(delta, k, [match]);
                        } else {
                          delta[k].pushObject(match);
                        }
                      }
                    } else {
                      // no match id, all we can do is dumb add
                      if (!Ember.isArray(Ember.get(delta, k))) {
                        Ember.set(delta, k, [match]);
                      } else {
                        delta[k].pushObject(match);
                      }
                    }
                  });
                } else {
                  Ember.set(delta, k, rhsMatch);
                }
              } else {
                Ember.set(delta, k, rhsMatch);
              }
            } else if (_this6.isObject(lhsMatch)) {
              if (!Ember.isEmpty(rhsMatch) && !_this6.isEmptyObject(rhsMatch)) {
                if ((Object.keys(lhsMatch) || []).length > 0) {
                  // You have more diffing to do
                  Ember.set(delta, k, _this6.diffEksUpstream(lhsMatch, rhsMatch));
                } else if (_this6.isEmptyObject(lhsMatch)) {
                  // we had a map now we have an empty map
                  Ember.set(delta, k, {});
                }
              }
            } else {
              // lhsMatch not an array or object
              Ember.set(delta, k, rhsMatch);
            }
          }
        }
      });
      return delta;
    },

    /**
     * True if obj is a plain object, an instantiated function/class
     * @param {anything} obj
     */
    isObject: function isObject(obj) {
      return obj // Eliminates null/undefined
      && obj instanceof Object // Eliminates primitives
      && _typeof(obj) === 'object' // Eliminates class definitions/functions
      && !Array.isArray(obj); // Eliminates arrays
    },
    isEmptyObject: function isEmptyObject(obj) {
      return this.isObject(obj) && Object.keys(obj).length === 0;
    }
  });

  _exports.default = _default;
});